/* BRanding Colors */

$WC-Blue: #0033A0;
$WC-Lime-green: #C4D600;
$WC-Teal: #071d49;
$WC-Purple: #5c068c;
$WC-NewTeal: #00778b;
$WC-Red: #e4002b;
$WC-DarkBlue: #071d49;
$WC-LinkBlue: #0072ce;
$WC-SkyBlue: #32a9e0;
$WC-Body-text: #474C55;
$WC-Headings: #000000;
$WC-red-underline: #FF4E00;
$WC-Paragraph: #212529;;
$WC-bg: #F5F5F5;
$WC-orange:#e35205;

// Colour Classes

.wcOrange{
color: $WC-orange !important;
}

.wcLimeGreen{
    color: $WC-Lime-green !important;
}
.wcGreen{
    color: #509e2f;
}
.wcTeal{
    // color: $WC-Teal !important;
    color: $WC-NewTeal !important;
}
.wcBlue{
    color: $WC-Blue;   
}
.wcSkyBlue{
    color: $WC-SkyBlue;   
}
.wcDarkBlue{
    color: $WC-DarkBlue;   
}
.wcPurple{
    color: $WC-Purple;
}
.wcLightTeal{
    color: #00aec7;
}
.wcMint{
    color: #6bbbae;
}


/* Navbar Toggler */
.navbar-toggler-icon {
//   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler {
  border-color: $WC-Lime-green !important;
}
/* Menu Carets */
.dropdown-toggle:after {
     transition: all 0.3s ease-in-out;
     transform: rotate(0deg);
     /* brand */
     color: $WC-Lime-green;
     border-top: .4em solid !important;
    border-right: .4em solid transparent !important;
    border-left: .4em solid transparent !important;
}
.dropdown-toggle.searchButton:after{
    display: unset;
    width: 0;
    height: 0;
    margin-left: unset;
    vertical-align: unset;
    content: "";
    border-top: unset !important;
    border-right: unset !important;
    border-bottom: unset !important;
    border-left: unset !important;
}
.updateCaret:after{
    transform: rotate(-180deg);
   
}
h1.topNav{

}
h1.topNav a, ul.childNav{
    color: $WC-Blue;
}
h1.topNav a{
    color: $WC-Blue !important;
}
h1.topNav a:hover{
    color: $WC-SkyBlue !important;
}
ul.childNav li p{
    color: $WC-Paragraph;
}
ul.childNav li a{
    color: $WC-Blue;
}
ul.childNav li a:hover{
    color: $WC-SkyBlue;
}
p a{
    color: $WC-Blue;
}
p a:hover{
    color: $WC-SkyBlue;
}
.wcLink,a{
    color: $WC-Blue;
}
.wcLink:hover,a:hover{
    color: $WC-SkyBlue;
}
.wcLinkBlack{
    color:#888888 !important;
}

.footerWrapper{
    a{
        h1{
            color: $WC-Blue;
        }
    }
    a.directLink{
        position:relative;
        top:-0.25rem;
    }
}

ul.childNav li::before {
  color: $WC-Blue; /* Change the color */
}

.topRightMenu ul li.telephoneNumber{
   color: $WC-Blue;
}
.topRightMenu ul li a{
    color: $WC-Blue;
}
.button-brand{
border: 2px solid $WC-Lime-green;
font-family: 'Gotham-Bold';
font-size: 0.9rem !important;
transition: 0.3s;
padding: 0.7em 0.9em 0.6em 0.9em;
}

.button-brand:hover{
    background-color:$WC-Lime-green;
    text-decoration: none !important;
    padding: 0.7em 0.9em 0.6em 0.9em;
    border: 2px solid $WC-Lime-green;
}
.button-brand:hover{
    text-decoration: none;
}
.tagline-catch-phrase h3{
        color: $WC-Blue;
    }
.sideMenu h1.topNav{
    color: $WC-Blue;
}
main{
    color: $WC-Body-text;
    .contentWrapper .solutionsBlock .solutionsContainerInfo h1{ 
        color: $WC-Teal;
    }
    .contentWrapper .solutionsBlock .solutionsContainerInfo h2, main .contentWrapper .solutionsBlock .solutionsContainerInfo p{ 
        color: $WC-Body-text;
    }
    .subscribeBlock h1{
        color: #ffffff !important;
    }
    .toolBox h1{
        color: $WC-Blue;
    }
    .toolBox h2{
        color:$WC-Body-text; 
    }
    .testimonials .content .testimonialsSlider .slick-list.draggable {
        color: #fff;
     }
     .pageBanner .CTA1:after  {
        background: $WC-Teal;
        border-left: 10px solid $WC-Lime-green
      }
}

main{
    .successStories{
        h1{
            color: $WC-Blue;
        }
        .content{
            
            .storySlider{
                font-family: 'Gotham-Book';
                h1{
                    color: $WC-Headings;
                }
                p{
                    color:$WC-Body-text;
                }
            }
        }
    }
}

main{
    .connect-expert{
        background-color: $WC-Blue;
        // background-image: url('../images/WCD_Graphic_greyscale.svg');
        // background-size: cover;
        // background-position: center;
        // background-repeat: no-repeat;
        position: relative;
    }
    .connect-expert:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.1;
        background-image: url(../images/WCD_Graphic_greyscale.svg);
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }
}
main{
    
    .blog{

        .container{

            .row{
                [class^="col"]{

                    p:first-of-type{

                    }
                }
            }
            
        }
        h1{
            color: $WC-Blue;
        }
        h2{

            // color: $WC-Headings;


        }
    }
}

footer{
    .menu{
     color:$WC-Blue;
    }
    .wcInfo{
        h1{
            color:$WC-Blue;
        }
        h2{
            color:$WC-Body-text;
        }
    }   
}


/*Buttons and Links*/
.buttonContainer{
    margin-bottom:4em;
    margin-top: 3em;
}
.wcButton, .cc-f-btn, .hs-button{
    background-color: $WC-Lime-green  !important;
    color: $WC-Headings  !important;
}
.wcButton:hover, .cc-f-btn:hover, .hs-button:hover{
    background-color: $WC-Blue !important;
    color: #fff !important;
    text-decoration: none;
}

.hs-cta-wrapper{
    display:block;
    text-align:center;
    .hs-cta-node{
        a{
            background-color: $WC-Lime-green !important;
            color: $WC-Headings !important;
        }
        a:hover{
            background-color: $WC-Teal !important;
            color: #fff !important;
        }
    }
}



.wcLink:after {
    border-bottom: solid 3px $WC-Lime-green;  
  }
  .wcLink.fromCenterLead:after {

    border-bottom: solid 3px $WC-Lime-green;    

  }
  .wcLink.fromLeftLead:after {
    border-bottom: solid 3px $WC-Lime-green;  

  }
  a:after{
    border-bottom: solid 3px $WC-Lime-green;  
  }